import React from 'react'
import { Textfit } from 'react-textfit'
import styled from 'styled-components'
import { TitleMixin } from './elements'

import backgroundBox1 from '../images/background-box1.svg'
import titleBG from '../images/title-box1.svg'

const WinnersHeaderWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.colorGreen};
  background-image: url(${backgroundBox1});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: ${(props) => props.theme.colorWhite};
  h1 {
    ${TitleMixin}
    width: 100%;
    font-size: 300px;
    font-style: normal;
    margin-bottom: 0;
  }
`

const WinnersHeaderTitleWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  transform: translateX(-100%);
  @keyframes animateHeader {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(110%);
    }
  }
  animation-name: animateHeader;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;

  &:after {
    content: '';
    position: absolute;
    top: -15%;
    right: 0;
    bottom: -15%;
    display: block;
    width: 200px;
    background-image: url(${titleBG});
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
    transform: translateX(100%);
  }
`

const WinnersHeader = () => {
  return (
    <WinnersHeaderWrapper>
      <WinnersHeaderTitleWrapper>
        <h1>
          <Textfit mode="single" max={400}>
            zwycięzcy
          </Textfit>
        </h1>
      </WinnersHeaderTitleWrapper>
    </WinnersHeaderWrapper>
  )
}

export default WinnersHeader
