import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import WinnersHeader from '../components/winners-header'
import {
  ColMixin,
  ContainerMixin,
  RowMixin,
  PaddingsContainerMixin,
  TitleMixin,
} from '../components/elements'

import backgroundBox1 from '../images/background-box1.svg'

const WinnersPageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  background-color: ${(props) => props.theme.colorGreen};
  background-image: url(${backgroundBox1});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: ${(props) => props.theme.colorWhite};
  padding-top: 50px;
  h1,
  h2 {
    ${TitleMixin}
    font-size: 32px;
    font-style: normal;
    text-align: center;
    margin-bottom: 15px;
  }
  p {
    text-align: center;
    margin-bottom: 30px;
  }
  ol {
    li {
      list-style-type: decimal;
      line-height: 1.2em;
      text-transform: uppercase;
    }
  }
`

const WinnersPageContainer = styled.div`
  ${ContainerMixin}
  ${PaddingsContainerMixin}
  width: 100%;
  padding-top: 50px;
`

const WinnersPageRow = styled.div`
  ${RowMixin}
  padding-bottom: 100px;
`

const WinnersPageCol = styled.div`
  ${ColMixin}
`

const WinnersPage = (props) => {
  return (
    <Layout title="Zwycięzcy - Schaeffler - Nie daj się przeoczyć!">
      <Navbar page="winners" visibleEffects={false} isBox={true} />
      <WinnersHeader />
      <WinnersPageWrapper>
      <WinnersPageContainer>
          <h2>Car Book</h2>
          <p>17.05.2021 - 30.05.2021</p>
          <WinnersPageRow>
            <WinnersPageCol>
              <ol>
                <li>"ARKO" ARKADIUSZ I TOMASZ MROZIŃSCY SP. JAWNA, CZĘSTOCHOWA</li>
                <li>AD SERWIS ADRON, Chełm</li>
                <li>AEC  INVEST ANNA  ĆWISZEWSKA, JELENIA  GÓRA</li>
                <li>AUTO HANDEL MECHANIKA POJAZDOWA, Koźminek</li>
                <li>Auto Konkret, Roznow</li>
                <li>Auto Market Ireneusz Rosiak, Turek</li>
                <li>Auto Matunin Spółka z o.o., Wrocław</li>
                <li>AUTO MOTO KLINIKA KRZYSZTOF GALA, Jaworzno</li>
                <li>Auto Naprawa Dariusz Masłowski, Płock</li>
                <li>Auto Naprawa Marek Rudnik, Rzeczenica</li>
                <li>Auto Naprawa Tadeusz Śmiarowski, Łomża</li>
                <li>Auto serwis "LUBOŃ", Wrocław</li>
                <li>Auto Serwis Kreft, Kartuzy</li>
                <li>Auto serwis Mirosław Talar, Krotoszyce</li>
                <li>AUTO SERWIS ZIELIŃSKI, Malbork</li>
                <li>Auto Tomek, Osieck</li>
                <li>AUTO-CZĘŚĆI, PRZEWORSK</li>
                <li>AUTOEXPRES, WĘGIERSKA GÓRKA</li>
                <li>auto-kala, Przywory</li>
                <li>AUTO-KLINIKA, Krosno</li>
                <li>AUTOMANIA Mechanika Samochodowa Adam Bartkowski , Kokoszkowy</li>
                <li>AUTO-MAR Marek Grys, Brzezina</li>
                <li>Autonaprawa Przemysław Małaj, Lublin</li>
                <li>AUTO-NAPRAWA TADEUSZ SZKODA, OLSZTYNEK</li>
                <li>Autoserwis juliusz bludnik, GOLDAP</li>
                <li>Autoset, Dąbrowa</li>
                <li>Auto-Sklep Auto-Naprawa A.Rutkowski, Malbork</li>
                <li>BLACHARSTWO I MECHANIKA SAMOCHODOWA BOGUSŁAW KACHEL, STRYSZAWA</li>
                <li>dagpon, witkowo</li>
                <li>DOCENT-SERWIS MOBILNY, KIELCE</li>
                <li>Extruckt sp. z o.o, warszawa</li>
                <li>F.H.KOMA AUTO-SERWIS MIROSŁAW KOZA, RADZYŃ PODLASKI</li>
                <li>FHU AUTORENOWACJA JACEK GĄBKA, Grudziądz</li>
                <li>FHU-ANTEK ANTONI PATYJEWICZ , BIAŁOPOLE</li>
                <li>FIRMA HANDLOWO USŁUGOWA EDEN RUDZIS REMIGIUSZ, WĘGORZEWO</li>
                <li>FIRMA USŁUGOWA ALICJA HARASYMOWICZ GALUCH, OLESZYCE</li>
                <li>GearBox , Nowogard</li>
                <li>GM SERWIS JAN TROCHIMCZYK, Białystok</li>
                <li>Goldwelder Yaroslav Kuznetsov, Oleśnica</li>
                <li>JP Serwis, Paździorno</li>
                <li>KIM Mechanika Pojazdowa Fiedziuszko Krzysztof, Wejherowo</li>
                <li>kordiano, koziegłowy</li>
                <li>KOZAKI AUTO NAPRAWA, kwidzyn</li>
                <li>LIDMAR, KOSTRZYN WLKP</li>
                <li>Mag-Serwis Jacek Wódkiewicz, Jednorożec</li>
                <li>MDD TRANS Mateusz Drewienkowski, Biała Podlaska</li>
                <li>MECHANIKA I BLACHARSTWO POJAZDOWE WALDEMAR MARCHLIK, bydgoszcz</li>
                <li>mechanika pojazdowa, ciechanów</li>
                <li>Mechanika Pojazdowa ,,BLANCAR Rafał Gala, Bednary</li>
                <li>Mechanika Pojazdowa Czesław Latusek, Katowice</li>
              </ol>
            </WinnersPageCol>
            <WinnersPageCol>
              <ol start="51">
                <li>Mechanika pojazdowa korneluk, Gdynia</li>
                <li>Mechanika Pojazdowa krzysztof kowalewski, Białystok</li>
                <li>Mechanika Pojazdowa Mariusz Kędziora, Głowno</li>
                <li>Mechanika Pojazdowa Robert Grzesiak, Lututów</li>
                <li>Mechanika pojazdowa TOP-GEAR, Wojkowice Kościelne</li>
                <li>mechanika pojazdowa Witczak &amp; cybulski, wrzosowo</li>
                <li>mechanika samochodowa, prudnik</li>
                <li>MECHANIKA SAMOCHODOWA "GABRIEL" KRYSTIAN KUŁAGA, WĄDROŻE WIELKIE</li>
                <li>Michał Kubień , Skoczów</li>
                <li>MK SERWIS Marcin Kowal, Wola Suchożebska</li>
                <li>MOBILNE USŁUGI MECHANICZNE DANUTA KOSTUCH, NOWA WIEŚ LĘBORSKA</li>
                <li>Naprawa Pojazdów Samochodowych Zbigniew Walczak, Słomniki</li>
                <li>Naprawa samochodów, Tuliłów</li>
                <li>P.H.U. "MAX" Andrzej Markuszewski, Kurzętnik</li>
                <li>P.H.U. WIELICZKO, Syców</li>
                <li>Peter, Pabianice</li>
                <li>PHU Wolnik Mirosława Wolnik, Rębiechowo</li>
                <li>PILZAK AUTO Damian Pilzak, Nowodwór</li>
                <li>Pio-Mar Auto Naprawa Piotr Stępień Marcin Rogala, Bełchatów</li>
                <li>Piotr Penczek Car Club Warsztat Samochodowy, Chełm Śląski </li>
                <li>PITSTOP Marcin Szlasa, WARSZAWA</li>
                <li>PKA MOTO GRUPA, Częstochowa</li>
                <li>PROFIXCAR SERWIS, Piaseczno</li>
                <li>Przedsiębiorstwo Handlowo Usługowe Ryszard Maguder, Kruszyn</li>
                <li>Punkt naprawy samochodów Mariusz Górny, Łobez</li>
                <li>raf-car, lipno</li>
                <li>RAMI, ZAGÓRZ</li>
                <li>RiM-SERVIS, Lębork</li>
                <li>rules auto serwis, środa śląska</li>
                <li>serwis pojazdowy, Pisz</li>
                <li>Serwis Samochodowy Lucjan Kowal, Łubnice</li>
                <li>Sklep Motoryzacyjny "MEGA-MOTOR" Miłosz Barański, Bukowsko</li>
                <li>SPEED CAR, Kędzierzyn-Koźle</li>
                <li>SPRINGER , Łomża</li>
                <li>Tomasz Szymenderski, Kostrzyn nad Odrą</li>
                <li>TOMIKAR , KRAKÓW</li>
                <li>TOTAL-TRUCK-TIR-SERWIS S.C. RADOSŁAW KMIN TOMASZ STĘPIEŃ, PABIANICE</li>
                <li>Towarowy Transport Drogowy Jan Lenartowicz, Busko Zdrój</li>
                <li>TRANS-WEG, JĘDRZEJÓW</li>
                <li>u przema, tomaszów maz</li>
                <li>Usługi Leśne Piotr Grzyb, Rzepedź</li>
                <li>VAG Performance Kozłowski Paweł, Krasnosielc</li>
                <li>Warsztat Samochodowy Sebastian Kruczyński, Ulhówek</li>
                <li>WarsztatB61, Płock</li>
                <li>WPUH PROGRES SPÓŁKA Z O.O., Chojna</li>
                <li>ZAKŁAD BRACI OPALA, KIELCE</li>
                <li>Zakład Mechaniki Pojazdowej Jednostka, Suwałki</li>
                <li>Zakład mechaniki pojazdowej samochody osobowe i dostawcze Henryk Kwietniewski, Nowa Wieś</li>
                <li>Zbigniew Zasada, Szczecin</li>
                <li>Zygmunt Wójtowicz Tomasz Wójtowicz Spółka Jawna , Poniatowa </li>
              </ol>
            </WinnersPageCol>
          </WinnersPageRow>
        </WinnersPageContainer>
        <WinnersPageContainer>
          <h2>Kaseton LED</h2>
          <p>03.05.2021 - 16.05.2021</p>
          <WinnersPageRow>
            <WinnersPageCol>
              <ol>
                <li>"IGOR GARAGE" Igor Margraf, Piła </li>
                <li>Adaśko, Łódź</li>
                <li>AMS SERWIS ARTYMOWICZ MICHAŁ, OLSZTYN</li>
                <li>ARPIS8 SPÓŁKA Z O.O., TYSZOWCE</li>
                <li>ASMOT  IRENA I KAZIMIERZ ANIELAK FELIKS SZABLA, KRASNYSTAW</li>
                <li>Auto - Części Kurcek - Koral S.C., Medyka</li>
                <li>AUTO CZEK Sebastian Czekalski, Płońsk</li>
                <li>Auto Macher Krzysztof Kupiec, Mielec</li>
                <li>Auto Market Ireneusz Rosiak, Turek</li>
                <li>Auto Naprawa, Gzy</li>
                <li>Auto naprawa Dariusz Swierzbin, Filipów</li>
                <li>AUTO NAPRAWA EDWARD SITEK, HAżLACH</li>
                <li>AUTO POMOC Paweł Kluziak, Gdańsk</li>
                <li>auto premium, lodz</li>
                <li>Auto Roman , Stargard Szczeciński</li>
                <li>AUTO SERWICE ARTUR JUSZCZYK, Iwonicz</li>
                <li>Auto Serwis "CELCAR" Celebias Paweł, Jaskrów</li>
                <li>AUTO SERWIS GRZEGORZ CHUDZICKI, KŁAJ</li>
                <li>Auto Serwis Jan Regiec, Sromowce Nizne</li>
                <li>AUTO SERWIS Jolanta Kosz, Wrocław</li>
                <li>Auto Serwis Łukasz Tyrała, Włostów</li>
                <li>AUTO SERWIS ROMAN KUREK, SUCHA BESKIDZKA</li>
                <li>Auto Strefa AM, Wrocław</li>
                <li>AUTO-CHODOR S.C. MARCIN CHODOR KRZYSZTOF CHODOR, KUTNO</li>
                <li>Autodoktor, Potegowo</li>
                <li>AUTO-KOCZYK, GLINIANKA</li>
                <li>AUTOMAR, Radomyśl Wielki</li>
                <li>AUTO-MARKET, ŚWINOUJSCIE</li>
                <li>Automechanik, Zielonka</li>
                <li>AUTO-NAPRAWA Artur Domek, Łochowo</li>
                <li>Auto-Naprawa Mariusz Ziędarski, Olsztyn</li>
                <li>AUTOPANA SZYMON NAPIERAŁA, ROGOŹNO</li>
                <li>auto-serwis MK, Płońsk</li>
                <li>Autoset, Dąbrowa</li>
                <li>AUTO-USŁUGI Sławomir Urban, Dziecinin</li>
                <li>BERNI CAR , tczew</li>
                <li>BEST PARTS, Łódź</li>
                <li>BOG-MAR P.H.U., WYSZKÓW</li>
                <li>CAR-TECH pro Andrzej Rogacewicz, ZIELONA GÓRA</li>
                <li>CONCORD, Lidzbark Warmiński</li>
                <li>darmarcars marcin olszewski, nasielsk</li>
                <li>DM-AUTO MATEUSZ JEZ, LUBOJENKA</li>
                <li>Extruckt sp. z o.o, warszawa</li>
                <li>F.H.KOMA AUTO-SERWIS MIROSŁAW KOZA, RADZYŃ PODLASKI</li>
                <li>F.H.U SK-LUZ, Jasło</li>
                <li>F.H.U. "MECHANIC" KIELAN ŁUKASZ, RAWA MAZOWIECKA</li>
                <li>FHU "RD DYL" Rafał Dyl, Staszów</li>
                <li>FHU AUTORENOWACJA JACEK GĄBKA, Grudziądz</li>
                <li>Fhu Sławomir Wróbel , Szczecin</li>
                <li>FIRMA HANDLOWO-USLUGOWA Tomasz Fangrat, Polczyn Zdroj</li>
              </ol>
            </WinnersPageCol>
            <WinnersPageCol>
              <ol start="51">
                <li>Firma Usługowa "ST SERWIS", Ropa</li>
                <li>HIENKA ANGELIKA STANEK, KRAKOW</li>
                <li>JACK CAR SERWIS, Gorzów Wlkp</li>
                <li>KRISCAR KRZYSZTOF JELIŃSKI, SULĘCZYNO</li>
                <li>Ksara Ksepka Spółka Jawna, Zbójna</li>
                <li>LIDMAR, KOSTRZYN WLKP</li>
                <li>Marcin Sowa, Gródek nad Dunajcem</li>
                <li>Marecki auto naprawa, Wejherowo</li>
                <li>MATYSIK SERWIS, KRZESZOWICE</li>
                <li>M-Car, Pabianice</li>
                <li>MDB Dominika Bagłaj, Biała Podlaska</li>
                <li>mechanika pojazdowa, KROTOSZYN</li>
                <li>Mechanika Pojazdowa  Zenon Richert, Przodkowo</li>
                <li>Mechanika Pojazdowa Handel Częściami i Transport W Szymański, Stawiszyn</li>
                <li>MECHANIKA POJAZDOWA inż. Jarosław Nowaczyk, WĄSOSZ</li>
                <li>Mechanika Pojazdowa Mariusz Błaszkiewicz, Radomin</li>
                <li>Mechanika Pojazdowa Roman Rojek, Malbork</li>
                <li>Mechanika Pojazdowa Sławomir Wolak, Czechowice-Dziedzice</li>
                <li>MECHANIKA SAMOCHODOWA KAMIL TOMASZEWSKI, Lublin</li>
                <li>MMK-MOTO, MYSLENICE</li>
                <li>Moto-Golik, Chojnice</li>
                <li>M-Partner , Tychy</li>
                <li>MT SERWIS Michał Traczyk , Piastów</li>
                <li>Naprawa Pojazdów Samochodowych, Pludry</li>
                <li>NET-CARS BARTŁOMIEJ WALEWSKI, Warszawa</li>
                <li>P H U DARKO DARIUSZ OSADNIK, SKOMLIN</li>
                <li>P.N.Serwis Piotr Nowacki, Bodzanów</li>
                <li>PHU MKA Andrzej Antkiewicz, Dąbie</li>
                <li>PHU MPM-Auto Małgorzata Żmijewska, Dąbrówka</li>
                <li>Pilichowski Marcin, Pniewo</li>
                <li>Piotr Penczek Car Club Warsztat Samochodowy, Chełm Śląski </li>
                <li>PPHU BART-POL, Pakosław</li>
                <li>RAMI, ZAGÓRZ</li>
                <li>RS Auto-Serwis, Strzyżów</li>
                <li>SCAN-AUTO Andrzej Mikołajczak, Biała Podlaska</li>
                <li>Serwis 4x4 , Wrocław</li>
                <li>Serwis mobilny leg kar pawel karbowiak, Legnica</li>
                <li>serwis pojazdowy, Pisz</li>
                <li>Serwis Samochodowy Lucjan Kowal, Łubnice</li>
                <li>Spec Serwis, Gdańsk</li>
                <li>SPÓŁDZIELNIA TRANSPORTU WIEJSKIEGO, Nowy Sacz</li>
                <li>TechMot, Pęgów</li>
                <li>Turbo-Expert S.C. Jacek kokot, Artur arndt, Siemianowice Śląskie</li>
                <li>TurboPort Sp. z o.o., Szczecin</li>
                <li>Ulex, Secemin</li>
                <li>Węgrzyn & Węgrzyn - Auto Serwis Węgrzyn S.J., Kraków</li>
                <li>Z.U.H Moto-Gosz Krystian Miedziński, LĘBORK</li>
                <li>Zakład elektromechaniki i mechaniki pojazdowej Beata Gehfeld, Grudziądz</li>
                <li>Zakład Mechaniki Pojazdowej Wojciech Piekarski, Kalisz</li>
                <li>zakład wulkanizacji lech frankowski i syn, police</li>
              </ol>
            </WinnersPageCol>
          </WinnersPageRow>
        </WinnersPageContainer>
        <WinnersPageContainer>
          <h2>Drukarka Atramentowa</h2>
          <p>19.04.2021 - 02.05.2021</p>
          <WinnersPageRow>
            <WinnersPageCol>
              <ol>
                <li>AGENCJA SKORPION, STARACHOWICE</li>
                <li>ARIS-AUTO, STARGARD SZCZECIŃSKI</li>
                <li>AUTO - CZESCI SKARZYŃSKI, WŁOCŁAWEK</li>
                <li>Auto Craft Helena i Zbigniew Wowk Sp.J., Stalowa Wola</li>
                <li>AUTO EXPERT PIOTR ŚWIDER, SANOK</li>
                <li>auto Mechanika, Frampol</li>
                <li>AUTO NAPRAWA, LIPIE GÓRY</li>
                <li>Auto Naprawa Krzysztof Kroll, Ostrów Wielkopolski</li>
                <li>Auto Naprawa Lipniewski Piotr, chełmno</li>
                <li>AUTO NAPRAWA MiB STARĘGA, SIEDLCE</li>
                <li>AUTO SERWIS  SPEED-CARS , DĄBROWA GÓRNICZA</li>
                <li>Auto serwis Jakub Sarna, orzesze</li>
                <li>Auto Serwis jarosław pacholski, goleniów</li>
                <li>Auto Serwis Seby - Sebastian Telus, Opoczno</li>
                <li>AUTO TECHNIK, Szczecin</li>
                <li>AUTO-COMPLEX , Morawica</li>
                <li>Auto-Części Jacek Tworek, Nowogard</li>
                <li>AUTOELEKTRO Łysoń Ireneusz, Andrychów</li>
                <li>AUTO-FIX SERAFIN SP. J., WARSZAWA</li>
                <li>AUTO-MARKET, ŚWINOUJSCIE</li>
                <li>Automechanika , chodkowo działki</li>
                <li>AUTO-MOTO CENTRUM Z.Gegniewicz, K.Włodarska sp.j., Teresin</li>
                <li>Autoserwis Karol Stachowicz, bydgoszcz</li>
                <li>Autoserwis Warsztat Mechaniki i Djagnostyki Samochodowej, Bodzentyn</li>
                <li>Autoset, Dąbrowa</li>
                <li>auto-sklep-serwis, lewin brzeski</li>
                <li>CAR CRAFT SERVICE BASTEK SEBASTIAN JARNOT, Bieruń</li>
                <li>Car-Wik, Legnickie Pole</li>
                <li>DAR-MOT DARIUSZ KLUPA, SŁOMNIKI</li>
                <li>Elektromechanika Pojazdowa, Kozy</li>
                <li>Elektryka i Mechanika Samochodowa Rafał Olszewski, Białystok</li>
                <li>EUROTIM EDWARD NIEDBALSKI, BYDGOSZCZ</li>
                <li>Extruckt sp. z o.o, warszawa</li>
                <li>Fabryka Mocy, Szczecin</li>
                <li>FHU "RD DYL" Rafał Dyl, Staszów</li>
                <li>FHU AUTO-CZĘŚĆI BERNADETA SZELIGA, SĘDZISZÓW MŁP.</li>
                <li>Fhu Sławomir Wróbel , Szczecin</li>
                <li>firma usługowa Arkadiusz Ciskowski, Mrągowo</li>
                <li>Firma Usługowa Auto-Nowak Andrzej Nowak, Mogilno</li>
                <li>Flis Car Piotr Flis, Minsk Mazowiecki</li>
                <li>FUH RODZEŃ, GORZÓW WIELKOPOLSKI</li>
                <li>Garage Auto Service, Warszawa</li>
                <li>GP Auto Garage Przemysław Gąciarz, Gryfino</li>
                <li>ice-mobil Robert Nalewka, Bydgoszcz</li>
                <li>INTERAUTO, TOMASZÓW LUBELSKI</li>
                <li>Jakub Przytulski,  Bieruń</li>
                <li>Jarosław Musiał, Legnica</li>
                <li>JPG Auto Jakub Gradowski, Skiernewice</li>
                <li>KF AUTO, LIPNO</li>
                <li>KOPEX, KIELCE</li>
              </ol>
            </WinnersPageCol>
            <WinnersPageCol>
              <ol start="51">
                <li>KT Serwis Konrad Tusiewicz, Marki</li>
                <li>Lewart Markus Dariusz Markuszewski, Gdańsk</li>
                <li>LIDMAR, KOSTRZYN WLKP</li>
                <li>LUKCAR WIDŁAK ŁUKASZ, tarnów</li>
                <li>ŁUKASZ BOROWSKI, BIELANY WROCŁAWSKIE</li>
                <li>Maksman Garage, Stare Czarnowo </li>
                <li>mechanika blacharstwo pojazdowe sklep motoryzacyjny, drzewianowo</li>
                <li>MECHANIKA POJAZDOWA inż. Jarosław Nowaczyk, WĄSOSZ</li>
                <li>mechanika pojazdowa rafał olejnik, wołczyn</li>
                <li>mechanika pojazdowa zbigniew trempała, falmierowo</li>
                <li>mechanika samochodowa, prudnik</li>
                <li>MECHANIKA SAMOCHODOWA BARTOSZ HERTMAN , OŁAWA</li>
                <li>Mechanika samochodowa GaraSZ, Stradunia</li>
                <li>Mechanika samochodowa Mariusz Sznayder, Sieroszewice</li>
                <li>Mobilek Części Samochodowe Bartosz Kubach, SUSZ</li>
                <li>motobaj tomasz żynis, mragowo</li>
                <li>Moto-Tec , Czyżew </li>
                <li>MTD CAR, Radzymin</li>
                <li>NET-CARS BARTŁOMIEJ WALEWSKI, Warszawa</li>
                <li>Okręgowa Stacja Kontroli Pojazdów, Bedlno</li>
                <li>P. H. U. Automix, Racibórz </li>
                <li>P. H. U. DYMEX  ZBIGNIEW MIELEWCZYK, KOŚCIERZYNA</li>
                <li>P.H.U. AUTO-SERWIS MAROSZEK DAMIAN MAROSZEK, KANIÓW</li>
                <li>P.U.H. TAD-ROM, MIKOŁÓW</li>
                <li>PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI, BRZEZINY</li>
                <li>Piotr Penczek Car Club Warsztat Samochodowy, Chełm Śląski </li>
                <li>PPHU KONTRAKT DARIUSZ KWIATKOWSKI, Konin</li>
                <li>Premium Car Service PIT STOP, Działdowo</li>
                <li>PRO-CARS MARCIN TYMICKI, Krasnystaw</li>
                <li>Prolen Mechanika Samochodowa Marcin Prokop , Bielawa</li>
                <li>RAD-TRANS, Bydgoszcz</li>
                <li>raf-car, lipno</li>
                <li>ROCKOPONY DANIEL ADAMSKI, Białystok</li>
                <li>ROSSOCORSA, hecznarowice</li>
                <li>SAABIX, Lublin</li>
                <li>Skoda Polmozbyt, Lodz</li>
                <li>Speed Trans Kamil Kowalski, Wielun</li>
                <li>SPRINGER , Łomża</li>
                <li>tdi-vag serwis, Żurawica</li>
                <li>TEAM-CAR Cegielnia s. c. , Łódź </li>
                <li>TOM-CARS, Kielce</li>
                <li>TOMIF CAR KATARZYNA WILIŃSKA FILIPCZAK, ŁÓDŹ</li>
                <li>Usługi Mechaniczne, Jawor</li>
                <li>Usługi Transportowe Jan Ciak, Mrągowo</li>
                <li>Volwicar, łódź</li>
                <li>WARSZTAT SAMOCHODOWY , USTROŃ</li>
                <li>WARSZTAT SAMOCHODOWY PIT STOP, Szczecin</li>
                <li>Zakład Mechaniki Pojazdowej Janusz Kruk, Mońki</li>
                <li>Zakład mechaniki pojazdowej marzec krzysztof, Lubartów</li>
                <li>ZUH Auto Service, Zwoleń</li>
              </ol>
            </WinnersPageCol>
          </WinnersPageRow>
        </WinnersPageContainer>
        <WinnersPageContainer>
          <h2>Odkurzacz warsztatowy YATO</h2>
          <p>05.04.2021 - 18.04.2021</p>
          <WinnersPageRow>
            <WinnersPageCol>
              <ol>
                <li>"AUTO - LUX" KRZYSZTOF KOWALCZYK, Ząbki</li>
                <li>A.S.C. USŁUGI MOTORYZACYJNE ARTUR MALON, OSTROŁEKA</li>
                <li>A.T. SERVICE KRZYSZTOF NOWICKI, BIELSKO-BIALA</li>
                <li>AK-CAR, KUJAKOWICE GÓRNE</li>
                <li>AMS Martyna Malcher, Wschowa</li>
                <li>ANTER S.C., PANIÓWKI</li>
                <li>AP-MOTORS, słupsk</li>
                <li>As Auto Serwis Wrzesiński Sławomir, Sosnowiec</li>
                <li>ATI.Autoserwis Adam Hławiczka , Bażanowice </li>
                <li>Auto Expres, Wiśniowa</li>
                <li>Auto Miś, Szczecin</li>
                <li>Auto Naprawa Handel Paweł Góral, Gniezno</li>
                <li>Auto Naprawa Krzysztof Kroll, Ostrów Wielkopolski</li>
                <li>AUTO NAPRAWA MYSŁOWSKI PRZEMYSŁAW, POLKOWICE</li>
                <li>Auto Naprawa Rafał Hirsz, Puck</li>
                <li>Auto Partner Radosław Mróz, Garwolin</li>
                <li>Auto Serwis Seby - Sebastian Telus, Opoczno</li>
                <li>AUTO SERWIS ZIELIŃSKI, Malbork</li>
                <li>AUTO-CZĘŚCI "ŁUCZYCKI", ŚRODA ŚLĄSKA</li>
                <li>Auto-Części Jacek Tworek, Nowogard</li>
                <li>AUTOMAX TOMASZ FRANKOWSKI, ORNETA</li>
                <li>Auto-Moto Service Ryszard Duma, Tarnobrzeg</li>
                <li>AUTO-NAPRAWA Artur Domek, Łochowo</li>
                <li>AutoNextSerwis, Szczecin</li>
                <li>AUTO-PIR PAWEŁ KUCHARCZYK, PIŃCZÓW</li>
                <li>autoservice v8, bydgoszcz</li>
                <li>Autoserwis Warsztat Mechaniki i Djagnostyki Samochodowej, Bodzentyn</li>
                <li>AUTO-SPEC  ZBIGNIEW SOSNOWSKI, Małogoszcz</li>
                <li>Auto-Top, Darłowo</li>
                <li>Barycza Auto Serwis, Goleniów</li>
                <li>Big Johny garage, Skowarcz</li>
                <li>BIM SERVICE BARTŁOMIEJ WIĘCZKOWSKI, LUBAWA</li>
                <li>Blacharstwo mechanika lakiernictwo grzegorz telinga, Gliwice</li>
                <li>Blukem team idea Tomasz Smoliński, Widawa</li>
                <li>CAR-TECH, Wadowice</li>
                <li>DAR-MOT DARIUSZ KLUPA, SŁOMNIKI</li>
                <li>DYBO-CAR FIRMA HANDLOWO-USŁUGOWA, toruń</li>
                <li>Elektromechanika Pojazdowa, Kozy</li>
                <li>ELEKTROMECHANIKA POJAZDOWA MICHAŁ NOWACKI, OSTRZESZÓW</li>
                <li>EMMAX MAGDALENA MAKSYLLEWICZ, BYSTRZYCA KŁODZKA</li>
                <li>Extruckt sp. z o.o, warszawa</li>
                <li>F.H.AUTO-DAR, Bytom</li>
                <li>fhu vema zbigniew pamrów, MAŁKINIA GÓRNA</li>
                <li>Firma Motoryzacyjna ANET-POL Zbigniew Konieczko, MYSZKÓW</li>
                <li>Firma Uslugowo Handlowa Rafal Banecki, Czmon</li>
                <li>firma usługowa Arkadiusz Ciskowski, Mrągowo</li>
                <li>firma zielpol Jerzy Zielonka, Piotrków Trybunalski</li>
                <li>Flis Car Piotr Flis, Minsk Mazowiecki</li>
                <li>Gringo Wojciech Borowicki, Liniewo</li>
                <li>Hofi Car, Zabełków</li>
              </ol>
            </WinnersPageCol>
            <WinnersPageCol>
              <ol start="51">
                <li>Jakub Przytulski,  Bieruń</li>
                <li>JONY SERWIS, PORĄBKA IWKOWSKA </li>
                <li>kordiano, koziegłowy</li>
                <li>Krzysztof Falba Mechanika Pojazdowa, złotów</li>
                <li>LIDMAR, KOSTRZYN WLKP</li>
                <li>LIM-CARS, LIMANOWA</li>
                <li>M&P IMPORT-EKSPORT ANDRUSIUK PIOTR, leśna podlaska</li>
                <li>MAKSYMA KRZYSZTOF MACHNIJ, LUBAWA</li>
                <li>Mechanika Pojazdowa, Łęczna</li>
                <li>MECHANIKA POJAZDOWA CZESŁAW ŻBIK, BARTOSZ ŻBIK S.C., Żory</li>
                <li>MECHANIKA POJAZDOWA DAMIAN CIRÓG, OLSZANKA</li>
                <li>Mechanika Pojazdowa Mariusz Kędziora, Głowno</li>
                <li>Mechanika pojazdowa Piotr Dawidowicz, Nakło nad notecią</li>
                <li>Mechanika Pojazdowa Robert Grzesiak, Lututów</li>
                <li>Mechanika Pojazdowa Ryszard Olszewski, Warszawa</li>
                <li>Mechanika pojazdowa tomasz kutkowski, kowala</li>
                <li>MECHANIKA POJAZDOWA WEJ-KAS WEJNER PIOTR, chełm</li>
                <li>mechanika samochodowa, prudnik</li>
                <li>MIREX - Szandecki i Marczuk Sp.J., Biała Podlaska</li>
                <li>MOTO TEAM JULIUSZ TABACHARSKI, Olszyna</li>
                <li>MOTOKOMPLEKS DAWID MICHAŁEK, TRYSZCZYN</li>
                <li>MOTO-MIX JADWIGA CYKOWASK, KŁOBUCK</li>
                <li>MOTO-NORAL, RUDNIKI</li>
                <li>NAPRAWA POJAZDÓW SAMOCHODOWYCH GRZEGORZ ANDRUSZKIEWICZ, PASŁĘK</li>
                <li>NET-CARS BARTŁOMIEJ WALEWSKI, Warszawa</li>
                <li>NOSTER-PŁONKA SPÓŁKA JAWNA, Oświęcim</li>
                <li>OIL FANS Grzegorz Janus, Górno</li>
                <li>P H U DARKO DARIUSZ OSADNIK, SKOMLIN</li>
                <li>P.W BIBMOT BIK SP.J., MIELEC</li>
                <li>PHU ''Greg'', Piechowice</li>
                <li>PHU Manio trans, Konstancin-Jeziorna</li>
                <li>PHU SUSKI, Trzydnik duży</li>
                <li>PHU Wolnik Mirosława Wolnik, Rębiechowo</li>
                <li>Piotr Penczek Car Club Warsztat Samochodowy, Chełm Śląski </li>
                <li>PPUH MEGAT MACIEJ MICHALIK, BARLINEK</li>
                <li>Przedsiębiorstwo Handlowo Usługowe Ryszard Maguder, Kruszyn</li>
                <li>RADOX, Rawicz</li>
                <li>Rafal Helowicz, Przeclaw</li>
                <li>RKSERWIS, Warszawa</li>
                <li>SPRINGER , Łomża</li>
                <li>Stacja Obsługi Samochodów Edward Strzeżek, Warka</li>
                <li>TEAM-CAR Cegielnia s. c. , Łódź </li>
                <li>TEK TRANS, CZERNICHÓW</li>
                <li>TM AUTO Tomasz Marciniuk, Międzyrzec Podlaski</li>
                <li>TRE.ART.AUTO TRENDA ARTUR, CZĘSTOCHOWA</li>
                <li>U Jana, Olsztyn</li>
                <li>usługi mechaniki pojazdowej grzegorz szachta, Jabłowo</li>
                <li>VRSERVICE Wojciech Borysiuk, Choroszcz</li>
                <li>ZAKLAD MECHANIKI POJAZDOWEJ P.JAWORSKI, LUBOSZYCE</li>
                <li>Zakład Naprawy Maszyn i Urządzeń, Brzozów</li>
              </ol>
            </WinnersPageCol>
          </WinnersPageRow>
        </WinnersPageContainer>
        <WinnersPageContainer>
          <h2>Szlifierka kątowa MILWAUKEE</h2>
          <p>22.03.2021 - 04.04.2021</p>
          <WinnersPageRow>
            <WinnersPageCol>
              <ol>
                <li>ADAMEX Adam Szymla, Nowa Wieś</li>
                <li>AdekCar, Sędziejowice</li>
                <li>AGRO-MOTO majster Daniel Cichecki, Szczekociny</li>
                <li>AL&MIR, Pisarzowice</li>
                <li>AMS SERWIS ARTYMOWICZ MICHAŁ, Olsztyn</li>
                <li>Anjolstop, Grodków</li>
                <li>ARIS-AUTO, Stargard Szczeciński</li>
                <li>Auto Craft Helena i Zbigniew Wowk Sp.J., Stalowa Wola</li>
                <li>Auto DEMS, Sorkwity</li>
                <li>AUTO KLINIKA  TOMASZ KULIK, Dęblin</li>
                <li>Auto mar, Lipowa</li>
                <li>Auto Market Ireneusz Rosiak, Turek</li>
                <li>Auto naprawa Dariusz Swierzbin, Filipów</li>
                <li>AUTO NAPRAWA MYSŁOWSKI PRZEMYSŁAW, Polkowice</li>
                <li>AUTO NAPRAWA PRZEMYSLAW PARFINIUK, Swidnica</li>
                <li>auto naprawa viki, Mokoszyce</li>
                <li>AUto partner s.c., Libiąż</li>
                <li>AUTO SERWIS  Rafał Urbański, Chorzów</li>
                <li>Auto Serwis Katarzyna Piechnik, Rumia</li>
                <li>Auto Serwis Marcin Pabin, Międzyrzecz</li>
                <li>Auto serwis, Pieńsk</li>
                <li>AUTO-ALFA 1 GRZEGORZ HODUREK, Sułkowice</li>
                <li>Auto-Bazar S.C., Kalisz</li>
                <li>AUTO-CZAJA, Ełk</li>
                <li>Auto-Części Jacek Tworek, Nowogard</li>
                <li>AUTO-KAM, Ciechanów</li>
                <li>AUTO-NAPRAWA Artur Domek, Łochowo</li>
                <li>AUTO-SERWIS DEPTUCH, Jawor</li>
                <li>auto-śmiech mechanika pojazdowa, Krzemieniewo</li>
                <li>Automania, Drzycim</li>
                <li>Autonaprawa, Karwia</li>
                <li>Autoset, Dąbrowa</li>
                <li>Barycza Auto Serwis, Goleniów</li>
                <li>BEST PARTS, Łódź</li>
                <li>BG TURBO Bartosz Gulina, Nowe Miasto Nad Pilicą</li>
                <li>Blue car  Grzegorz Szostak, Warszawa</li>
                <li>bosch service grześkowiak - pietrzak, Rawicz</li>
                <li>CENTRUM MOTORYZACJI , Pisz</li>
                <li>DARIUSZ GROCHOWSKI FIRMA USŁUGOWO-HANDLOWA "DADA"., Zabrze</li>
                <li>DERASERWIS, Mysłowice</li>
                <li>EKO-M, Biała Podlaska </li>
                <li>Elektromechanika pojazdowa Konrad Kubiak, Luboń</li>
                <li>Elektromechanika Pojazdowa, Kozy</li>
                <li>Elektromechanika Pojazdowa, Mała Wieś</li>
                <li>Elektromechanika samochodowa, Kaczyce</li>
                <li>EURO-CARS S.C., Szamotuły</li>
                <li>Extruckt sp. z o.o, Warszawa</li>
                <li>Firma Handlowo - Usługowa Łukasz Buciak, Bielany Wrocławskie</li>
                <li>FIRMA HANDLOWO-USŁUGOWA ASB SEKUŁA SŁAWOMIR, Sokołów Podlaski</li>
                <li>firma usługowa Arkadiusz Ciskowski, Mrągowo</li>
              </ol>
            </WinnersPageCol>
            <WinnersPageCol>
              <ol start="51">
                <li>GM SERWIS JAN TROCHIMCZYK, Białystok</li>
                <li>Handel Artykułami Motoryzacyjnymi Artur Piotrowski, Brzeg</li>
                <li>Hofi Car, Zabełków</li>
                <li>KAMPOL1 RAFAŁ LOFEK, Bydgoszcz</li>
                <li>Karol Czarnecki, Radom</li>
                <li>Ksara Ksepka Spółka Jawna, Zbójna</li>
                <li>LIDMAR, Kostrzyn Wlkp</li>
                <li>Maciej Zawal, Poznań</li>
                <li>Mechanika Pojazdowa , Suszec-Rudziczka</li>
                <li>mechanika pojazdowa "U JACKA", Ciężkowice</li>
                <li>Mechanika Pojazdowa Artur Ertmann, Oborniki</li>
                <li>Mechanika Pojazdowa Krzysztof Soforek, Ostrówek</li>
                <li>MECHANIKA POJAZDOWA LUDA HUBERT, Łącznik</li>
                <li>Mechanika Pojazdowa Mariusz Kędziora, Głowno</li>
                <li>Mechanika Pojazdowa Zator Edward, Żagań</li>
                <li>Mechanika Samochodowa, Prudnik</li>
                <li>MiCo Car Michał Kotarski, Raczki</li>
                <li>MIRANT Mirosław Mikołajczyk, Włoszczowa</li>
                <li>Moto Centrum s.c., Dobczyce</li>
                <li>moto centrum, Zakrzew</li>
                <li>Moto-Auto CS, Wysokie Mazowieckie</li>
                <li>MTD Bogdan Drzyzga, Tymbark</li>
                <li>naprawa samochodów Barnaś Zbigniew, Skrzyszów</li>
                <li>NET-CARS BARTŁOMIEJ WALEWSKI, Warszawa</li>
                <li>NOWIX Piotr Nowak, Dobre</li>
                <li>OPLEX, Łódź</li>
                <li>OSA, Grabownica Starzeńska</li>
                <li>P. H. U. DYMEX  ZBIGNIEW MIELEWCZYK, Kościerzyna</li>
                <li>P.H.U. "AD-Cars", Zwoleń</li>
                <li>P.H.U. SŁAWOMIR ROGALSKI, Śmigiel</li>
                <li>P.U-H. "AUTO-KULA" RYSZARD KULA, Rędziny</li>
                <li>Pawel Calka naprawa pojazdow sprzedaz czesci motoryzacyjnych, Latowicz</li>
                <li>PERFECT AUTO, Otwock Mały</li>
                <li>PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI, Brzeziny</li>
                <li>phualfa, Chodziez</li>
                <li>Piotr Romanów, Międzyrzecz</li>
                <li>Piotrowska Elżbieta , Chojna</li>
                <li>POLMOZBYT-LEGIONOWO SP Z O.O., Legionowo</li>
                <li>Rs motors, Piekoszów</li>
                <li>Serwis pojazdowy, Pisz</li>
                <li>SiemianService, Mława</li>
                <li>Sprint-Ford Jarosław Kaczmarski, Głogów</li>
                <li>"STAMAR" Przedsiębiorstwo Wielobranżowe Stanisław Hałata, Mielec</li>
                <li>TIRPARTNER, Łuków</li>
                <li>Wieczorek i Syn, Szczecin</li>
                <li>WIST     KRZYSZTOF DRĄCZKOWSKI, Hrubieszów</li>
                <li>Zakład Handlowo Usługowy Tranzbet Grzegorz Łoś, Boleszkowice</li>
                <li>Zakład Mechaniczny Dariusz Pona, Twardogóra</li>
                <li>ZAKŁAD MECHANIKI POJAZDOWEJ D.JACKOWSKI, Kobylin</li>
                <li>Zakład Mechaniki Pojazdowej Janusz Kruk, Mońki</li>
              </ol>
            </WinnersPageCol>
          </WinnersPageRow>
        </WinnersPageContainer>
      </WinnersPageWrapper>
      <Footer />
    </Layout>
  )
}

export default WinnersPage
